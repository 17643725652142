import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import logo from '../assets/cs-studio/logo.svg';

const LogoText = styled.h1`
font-family: 'Akaya Telivigala', cursive;
font-size: ${props => props.theme.fontxxxl};
color: ${props => props.theme.text};
transition: all 0.2s ease;

&:hover{
    transform: scale(1.1);
}

@media (max-width: 128em){
font-size: ${props => props.theme.fontxxl};

}
`

const Logo = () => {
  return (
    <LogoText>
        <Link to="https://cs-studio.pl">
        <img width={200} height={386}  src={logo} alt="CS-Studio" />
        </Link>
    </LogoText>
  )
}

export default Logo