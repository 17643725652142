import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components';
import Accordion from '../Accordion';

import img1 from '../../assets/cs-studio/przyklad_v.webp';

const ImgContainer = styled.div`

width: 100%;
left: 50%;
display: flex;
justify-content: center;
align-items: center;


img{
    width: 25rem;
    height: auto;
}

}


`
const MyLink = styled.a`
`;
const Section = styled.section`
min-height: 100vh;
height: auto;
width: 100vw;
background-color: ${props => props.theme.text};
position: relative;
color: ${(props) => props.theme.body};
overflow: hidden;


display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
`
const Title = styled.h1`
  font-size: ${(props) => props.theme.fontxxl};
  text-transform: uppercase;
  color: ${(props) => props.theme.body};
  
  margin: 1rem auto;
  border-bottom: 2px solid ${(props) => props.theme.carouselColor};
  width: fit-content;

  @media (max-width: 48em){
  font-size: ${(props) => props.theme.fontxl};

  }
`;

const Container = styled.div`
width: 75%;
margin: 2rem auto;

display: flex;
justify-content: space-between;
align-content: center;

@media (max-width: 64em){
  width: 80%;
  }
  @media (max-width: 48em){
  width: 90%;
  flex-direction: column;

  &>*:last-child{
    &>*:first-child{

    margin-top: 0;
}

  }
  }
`
const Box = styled.div`
width: 45%;
@media (max-width: 64em){
  width: 90%;
  align-self: center;
  }

`

const Faq = () => {

const ref = useRef(null);
gsap.registerPlugin(ScrollTrigger);
useLayoutEffect(() => {
  
  let element = ref.current;

  ScrollTrigger.create({
    trigger: element,
    start:'bottom bottom',
    end:'bottom top',
    pin:true,   
    pinSpacing:false, 
    scrub:1,
    // markers:true,
  })

  return () => {
    ScrollTrigger.kill();
  };
}, [])

  return (
    <Section ref={ref} id="faq">
    <Title>Często zadawane pytania  </Title>

    <Container>

<Box>
  <Accordion ScrollTrigger={ScrollTrigger} title="JAK ZAMÓWIĆ PORTRET?" >
  Aby zamówić portret, wystarczy wysłać do nas jedno lub kilka ulubionych zdjęć przez <MyLink href="https://m.me/CanvasStoryStudio">messengera</MyLink>  lub <MyLink href="https://wa.me/48570575169">WhatsApp: +48 570 575 169</MyLink>.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="ILE WYNOSI CZAS REALIZACJI?" >
  Czas stworzenia projektu, wydruk oraz wysyłka zazwyczaj zajmują 4-5 dni roboczych.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="JAKA JEST CENA WYSYŁKI?" >
  Koszt wysyłki kurierem wynosi 9.99 zł.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="CO ZROBIĆ, JEŚLI NIE JESTEM ZADOWOLONY Z PROJEKTU PORTRETU?" >
  Jeśli projekt nie spełnia Twoich oczekiwań, nie martw się! Kasujemy Twoje zdjęcia, a Ty nic nie płacisz.
  </Accordion>
</Box>
<Box>
<Accordion ScrollTrigger={ScrollTrigger} title="CZY MOJE ZDJĘCIA SĄ BEZPIECZNE?" >
Tak, otrzymane zdjęcia są używane jedynie na potrzeby stworzenia portretu. Nie są nigdzie udostępniane ani używane w żadnych innych celach.
</Accordion>
<Accordion ScrollTrigger={ScrollTrigger} title="CZY WPROWADZAMY POPRAWKI?" >
W tej usłudze nie oferujemy poprawek. Jednakże, zapraszamy Cię do odwiedzenia naszego sklepu na stronie CS-Studio.pl, gdzie znajdziesz naszą regularną ofertę ze spersonalizowanymi obrazami.  Tam oferujemy obrazy  z możliwością pełnej personalizacji oraz poprawki do uzyskania pełnej satysfakcji! 
</Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="CZY MOGĘ ZAMÓWIĆ ZA POBRANIEM?" >
  Niestety, w chwili obecnej nie obsługujemy płatności za pobraniem. Na naszej stronie możesz bezpiecznie dokonać płatności za pośrednictwem BLIK, Przelewu natychmiastowego lub bezpośrednio na nasze konto bankowe.
  </Accordion>
  <Accordion ScrollTrigger={ScrollTrigger} title="ZDJĘCIE O SŁABEJ JAKOŚCI?" >
  To nie problem! Jesteśmy w stanie przygotować obraz już z jednego zdjęcia. Pamiętaj jednak, że im lepszej jakości zdjęcie nam dostarczysz, tym większe podobieństwo będziemy w stanie uzyskać. Zawsze jednak robimy co w naszej mocy aby jak najlepiej je oddać. Poniższa grafika pomoże Ci dobrać najlepsze zdjęcie:
  
  <ImgContainer>
        <img src={img1} width="1086" height="2026"  alt="Najlepsze zdjęcie" />
        
  </ImgContainer>
  </Accordion>
</Box>
    </Container>
    </Section>
  )
}

export default Faq